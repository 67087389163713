import React from 'react';

export const instagramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M15.43 7.715c0 1.065.01 2.12-.051 3.184-.06 1.236-.341 2.33-1.245 3.235-.905.903-2 1.185-3.235 1.245-1.065.06-2.12.05-3.184.05-1.065 0-2.12.01-3.184-.05-1.236-.06-2.33-.341-3.235-1.245-.904-.905-1.185-2-1.245-3.235C-.01 9.834 0 8.78 0 7.715 0 6.65-.01 5.595.05 4.53.11 3.295.392 2.2 1.296 1.296 2.2.392 3.296.111 4.531.051 5.596-.01 6.65 0 7.715 0c1.065 0 2.12-.01 3.184.05 1.236.06 2.33.341 3.235 1.245.903.904 1.185 2 1.245 3.235.06 1.065.05 2.12.05 3.184z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.672 7.715a3.952 3.952 0 01-3.957 3.957 3.952 3.952 0 01-3.958-3.957 3.952 3.952 0 013.958-3.958 3.952 3.952 0 013.957 3.958zM12.757 3.597a.922.922 0 01-.924.924.922.922 0 01-.924-.924c0-.513.412-.925.924-.925.513 0 .924.412.924.925z"
        clipRule="evenodd"
      ></path>
      <path
        fillRule="evenodd"
        d="M10.286 7.715a2.576 2.576 0 00-2.571-2.572 2.576 2.576 0 00-2.571 2.572 2.576 2.576 0 002.571 2.571 2.576 2.576 0 002.571-2.571z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
